// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgjJxpPOP49UXNgvO24YM0uM0-8Eq9x3s",
  authDomain: "earthsense-af645.firebaseapp.com",
  projectId: "earthsense-af645",
  storageBucket: "earthsense-af645.appspot.com",
  messagingSenderId: "119531483088",
  appId: "1:119531483088:web:a6285868a0d2b2fca193d5",
  measurementId: "G-J7444RD3SZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
